/** ***************************************************************************
 * Multi media loading file
 **************************************************************************** */
import './1_1_image.jpg';
import './1_2_image.jpg';
import './2_1_image.jpg';
import './2_2_image.jpg';
import './3_1_image.jpg';
import './3_2_image.jpg';
import './4_1_image.jpg';
import './4_2_image.jpg';
import './5_1_image.jpg';
import './5_2_image.jpg';
import './6_1_image.jpg';
import './6_2_image.jpg';
import './7_1_image.jpg';
import './7_2_image.jpg';
import './8_1_image.jpg';
import './8_2_image.jpg';
import './9_1_image.jpg';
import './9_2_image.jpg';
import './10_1_image.jpg';
import './10_2_image.jpg';
import './11_1_image.jpg';
import './11_2_image.jpg';
import './12_1_image.jpg';
import './12_2_image.jpg';
import './13_1_image.jpg';
import './13_2_image.jpg';
import './14_1_image.jpg';
import './14_2_image.jpg';
import './15_1_image.jpg';
import './15_2_image.jpg';
import './16_1_image.jpg';
import './16_2_image.jpg';
import './17_1_image.jpg';
import './17_2_image.jpg';
import './18_1_image.jpg';
import './18_2_image.jpg';
import './19_1_image.jpg';
import './19_2_image.jpg';
import './20_1_image.jpg';
import './20_2_image.jpg';
import './21_1_image.jpg';
import './21_2_image.jpg';
import './22_1_image.jpg';
import './22_2_image.jpg';
import './23_1_image.jpg';
import './23_2_image.jpg';
import './24_1_image.jpg';
import './24_2_image.jpg';
import './25_1_image.jpg';
import './25_2_image.jpg';
import './26_1_image.jpg';
import './26_2_image.jpg';
import './27_1_image.jpg';
import './27_2_image.jpg';
import './28_1_image.jpg';
import './28_2_image.jpg';
import './29_1_image.jpg';
import './29_2_image.jpg';
import './30_1_image.jpg';
import './30_2_image.jpg';
import './31_1_image.jpg';
import './32_1_image.jpg';
import './33_1_image.jpg';
import './33_2_image.jpg';
import './34_1_image.jpg';
import './34_2_image.jpg';
import './35_1_image.jpg';
import './35_2_image.jpg';
import './36_1_image.jpg';
import './36_2_image.jpg';
import './37_1_image.jpg';
import './37_2_image.jpg';
import './38_1_image.jpg';
import './38_2_image.jpg';
import './39_1_image.jpg';
import './39_2_image.jpg';
import './40_1_image.jpg';
import './40_2_image.jpg';
import './41_1_image.jpg';
import './42_1_image.jpg';
import './42_2_image.jpg';
import './43_1_image.jpg';
import './43_2_image.jpg';
import './44_1_image.jpg';
import './44_2_image.jpg';
import './45_1_image.jpg';
import './45_2_image.jpg';
import './46_1_image.jpg';
import './46_2_image.jpg';
import './47_1_image.jpg';
import './47_2_image.jpg';
import './48_1_image.jpg';
import './48_2_image.jpg';
import './49_1_image.jpg';
import './49_2_image.jpg';
import './50_1_image.jpg';
import './50_2_image.jpg';
import './51_1_image.jpg';
import './51_2_image.jpg';
import './52_1_image.jpg';
import './52_2_image.jpg';
import './53_1_image.jpg';
import './53_2_image.jpg';
import './54_1_image.jpg';
import './54_2_image.jpg';
import './55_1_image.jpg';
import './55_2_image.jpg';
import './56_1_image.jpg';
import './56_2_image.jpg';
import './57_1_image.jpg';
import './57_2_image.jpg';
import './58_1_image.jpg';
import './58_2_image.jpg';
import './59_1_image.jpg';
import './59_2_image.jpg';
import './60_1_image.jpg';
import './60_2_image.jpg';
import './61_1_image.jpg';
import './61_2_image.jpg';
import './62_1_image.jpg';
import './62_2_image.jpg';
import './63_1_image.jpg';
import './63_2_image.jpg';
import './64_1_image.jpg';
import './64_2_image.jpg';
