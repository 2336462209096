/** ***************************************************************************
 * Multi media loading file
 **************************************************************************** */

import './1_habitat.jpg';
import './2_habitat.jpg';
import './3_habitat.jpg';
import './4_habitat.jpg';
import './5_habitat.jpg';
import './6_habitat.jpg';
import './7_habitat.jpg';
import './8_habitat.jpg';
import './9_habitat.jpg';
import './10_habitat.jpg';
import './11_habitat.jpg';
import './12_habitat.jpg';
import './13_habitat.jpg';
import './14_habitat.jpg';
import './15_habitat.jpg';
import './16_habitat.jpg';
import './17_habitat.jpg';
import './18_habitat.jpg';
import './19_habitat.jpg';
import './20_habitat.jpg';
